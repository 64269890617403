<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Pages <span>Media Headline</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="page-media-headline-setting-form">
                <div class="modal_body pt-4">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Media Headline?</h5>
                            <label for="show_media_headline_setting" class="switch_option capsule_btn border-0">
                                <input type="checkbox" id="show_media_headline_setting" :true-value="1" :false-value="0" v-model="form.display_media_headline" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-show="form.display_media_headline == 1">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Media Headline</label>
                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.media_headline }">
                                        <Field autocomplete="off" type="text" name="media_headline" v-model="form.media_headline" placeholder="ex: Media Headline" />
                                    </div>
                                    <ErrorMessage name="media_headline" class="text-danger" />
                                </div>
                            </div>
                            <h3 class="sub_header mt-3">Headline Alignment</h3>
                            <div class="edit_section">
                                <div class="capsule_elm border-bottom">
                                    <h5>Left Align?</h5>
                                    <label for="left" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="left" value="left" v-model="form.media_headline_position" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm border-bottom">
                                    <h5>Center Align?</h5>
                                    <label for="center" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="center" value="center" v-model="form.media_headline_position" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Right Align?</h5>
                                    <label for="right" class="switch_option capsule_btn border-0 m-0">
                                        <input type="radio" id="right" value="right" v-model="form.media_headline_position" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <h3 class="sub_header mt-5">Headline Position</h3>
                            <div class="edit_section">
                                <div class="capsule_elm border-bottom">
                                    <h5>Above Media?</h5>
                                    <label for="above" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="above" value="above" v-model="form.media_headline_place" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Below Media?</h5>
                                    <label for="below" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="below" value="below" v-model="form.media_headline_place" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="pageUpdateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="pageUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="pageUpdateLoader"></i> {{ pageUpdateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Page Media Headline',

        data () {
            return {
                form: {
                    display_media_headline: 0,
                    media_headline: '',
                    media_headline_position: 'left',
                    media_headline_place: 'above',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            pageUpdateLoader: state => state.pageModule.pageUpdateLoader,
            selectedPage: state => state.pageModule.selectedPage,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePage: 'pageModule/updatePage',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    page_id: vm.selectedPage.id,
                    display_media_headline: vm.selectedPage.display_media_headline ? vm.selectedPage.display_media_headline : 0,
                    media_headline: vm.selectedPage.media_headline ? vm.selectedPage.media_headline : '',
                    media_headline_position: vm.selectedPage.media_headline_position ? vm.selectedPage.media_headline_position : 'left',
                    media_headline_place: vm.selectedPage.media_headline_place ? vm.selectedPage.media_headline_place : 'above',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;

                vm.updatePage(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style scoped>
    :deep(.page-media-headline-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.page-media-headline-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
</style>
